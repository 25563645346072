import axios from 'axios'
import { type AxiosRequestConfig, type AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import { useToast } from 'vuestic-ui'
const { init } = useToast()
// 全局配置
const apiUrl = import.meta.env.VITE_API_DOMAIN
axios.defaults.withCredentials = false
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
// 允许跨域
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

export function request(axiosConfig: AxiosRequestConfig): Promise<AxiosResponse<any>> {
  const service = axios.create({
    baseURL: apiUrl, // 设置统一的请求前缀
    timeout: 8000, // 设置统一的超时时长
  })
  // 请求拦截器
  service.interceptors.request.use(
    (config: any) => {
      const { headers } = config

      // 若是有做鉴权token , 就给头部带上token
      if (Cookies.get('joon_token')) {
        if (headers) {
          headers.Authorization = Cookies.get('joon_token') as string
        }
      }
      return config
    },
    (error: any) => {
      init({ message: error.data.error.message, color: 'danger' })
      return Promise.reject(error.data.error.message)
    },
  )
  // 响应拦截器
  service.interceptors.response.use(
    (res: AxiosResponse<any>) => {
      // 对响应数据进行处理，例如检查统一的字段（如 statusCode)
      if (res.status === 200 || res.data.status === 200) {
        return Promise.resolve(res.data)
      }
      return Promise.reject(res)
    },
    (error: any) => {
      if (error.response && error.response.status) {
        if (error.response.data.message.indexOf('请登录') !== -1) {
          init({ message: error.response.data.message, color: 'danger' })
          window.location.replace('/auth/login')
          return
        }
        init({ message: error.response.data.message, color: 'danger' })
        return Promise.reject(error)
      }
      return Promise.reject(new Error('网络请求失败，请稍后重试'))
    },
  )
  return service(axiosConfig)
}

export const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/** Validation */
export const validators = {
  email: (v: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return pattern.test(v) || '请输入有效的邮箱'
  },
  required: (v: any) => !!v || '必填字段',
}

export function textBecomeImg(text: string, fontsize = 66, fontcolor = '#fff') {
  const canvas = document.createElement('canvas')

  const context = canvas.getContext('2d')

  const firstTxt = text.slice(0, 1)
  canvas.width = 120
  canvas.height = 120
  // 擦除矩形，擦除的意思是把该区域变为透明
  context!.clearRect(0, 0, canvas.height, canvas.height)

  context!.fillStyle = fontcolor
  context!.font = fontsize + 'px Arial'
  context!.textBaseline = 'middle'
  context!.textAlign = 'center'
  context!.fillText(firstTxt, canvas.width / 2, canvas.height / 2 + 5)

  const dataUrl = canvas.toDataURL('image/png') //注意这里背景透明的话，需要使用png
  return dataUrl
}

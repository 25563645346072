import { request } from '../utils'

const loginApi = async (data: { email: string; password: string }, googleCode: string) =>
  await request({
    url: '/auth',
    headers: {
      recaptcha: googleCode,
    },
    method: 'post',
    data,
  })

const signupApi = async (data: { email: string; password: string; emailCode: string }, googleCode: string) =>
  await request({
    url: '/user',
    headers: {
      recaptcha: googleCode,
    },
    method: 'post',
    data,
  })

const emailCodeApi = async (data: { email: string }, googleCode: string) =>
  await request({
    url: '/emailcode',
    headers: {
      recaptcha: googleCode,
    },
    method: 'post',
    data,
  })
const findEmailCodeApi = async (data: { email: string; emailCode: string }) =>
  await request({
    url: '/emailcode',
    method: 'get',
    data,
  })

const getUserInfoApi = async () =>
  await request({
    url: '/user/getUserInfo',
    method: 'post',
  })

// 发送重置邮件
const sendResetPasswordApi = async (data: { email: string }, googleCode: string) =>
  await request({
    headers: {
      recaptcha: googleCode,
    },
    url: `/user/sandResetPassword`,
    method: 'post',
    data,
  })

const resetPasswordApi = async (data: { email: string; token: string; password: string }, googleCode: string) =>
  await request({
    headers: {
      recaptcha: googleCode,
    },
    url: `/user/resetPassword`,
    method: 'post',
    data,
  })

export { loginApi, signupApi, emailCodeApi, findEmailCodeApi, getUserInfoApi, sendResetPasswordApi, resetPasswordApi }

import { request } from '../utils'

export const getPricePlanApi = async () =>
  await request({
    url: '/price-plans',
    method: 'get',
  })

export const getAlipayQrCodeApi = async (params: { id: string }) =>
  await request({
    url: '/alipay',
    method: 'get',
    params: params,
  })

export const queryOrderApi = async (data: { outTradeNo: string }) =>
  await request({
    url: '/alipay/queryOrder',
    method: 'post',
    data,
  })

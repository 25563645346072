import { defineStore } from 'pinia'
import { getPricePlanApi } from '../services/api/price'

interface PricePlan {
  _id: string
  name: string
  categorization: string
  days: number
  ps?: string
  rebate: number
  price: number
  isGpt4: boolean
  gpt4num: number
  isDrawing: boolean
  isReadFile: boolean
}

export const usePriceStore = defineStore('price', {
  state: () => {
    return { pricePlans: [] as PricePlan[] }
  },

  actions: {
    async getPricePlans() {
      const { data } = await getPricePlanApi()
      if (data) {
        this.$state.pricePlans = data
      }
    },
  },
})

import { defineStore } from 'pinia'
import { getUserInfoApi } from '../services/api/users'
import dayjs from 'dayjs'
const vipLv: {
  [key: number]: string
} = {
  0: '基础会员',
  1: '初级会员',
  2: '高级会员',
}

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      email: '',
      vip: '',
      vipExpTime: '',
      useChatNum: 0,
      vipChatNum: 0,
      avatar: '',
    }
  },

  actions: {
    async getUser() {
      const { data } = await getUserInfoApi()
      if (data) {
        this.$state = data
        this.avatar = data.email.slice(0, 1)
        this.vipExpTime = dayjs(data.vipExpTime).format('YYYY-MM-DD')
        this.vip = vipLv[data.vip]
      }
    },
  },
})
